// DEPS
import { CloseOutlined } from "@ant-design/icons";
import { useSubscription } from "@apollo/client";
// COMPONENTS
import { Dropdown, Menu, notification } from "antd";
import Modal from "antd/lib/modal/Modal";
import gql from "graphql-tag";
// import jwtDecode from "jwt-decode";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { deleteAllCookies, getValueFromCookie } from "../utils/cookies";
import { getValueFromLocalStorage, clearAllLocalStorage } from "../utils/local-storage";

// ASSETS
import Logo from "../assets/logo@2x.png";
import NotificationIcon from "../assets/notification.svg";
import Warning from "../assets/warning.svg";

import { logout } from "../utils/userSessions";
import UsersIconSidebar from "./svg/UserIconSidebar";
import { useNetworkStatus } from "./NetworkStatusContext";

const GET_REQUESTED_VISITS = gql`
  subscription getAssignedVisits {
    visits_aggregate(where: { visit_status: { _eq: "VISIT_REQUESTED" } }) {
      aggregate {
        count
      }
      nodes {
        id
        visit_display_id
      }
    }
  }
`;

const GET_LOANS_PENDING_OROCORP_APPROVAL = gql`
  subscription getLoansPendingOrocorpApproval {
    loans_aggregate(
      where: { loan_status: { _eq: "PENDING_OROCORP_APPROVAL" } }
    ) {
      aggregate {
        count
      }
      nodes {
        id
        loan_number
      }
    }
  }
`;

const GET_LOANS_RENEWAL_PENDING_FUND_TRANSFER = gql`
  subscription getLoansPendingOrocorpApproval {
    loans_aggregate(
      where: { loan_status: { _eq: "RENEWAL_PENDING_FUND_TRANSFER" } }
    ) {
      aggregate {
        count
      }
      nodes {
        id
        loan_number
      }
    }
  }
`;

const GET_STORED_LOANS = gql`
  subscription getStoredLoans($date: timestamptz!) {
    loans_aggregate(
      where: {
        loan_status: { _eq: "GOLD_STORED" }
        partner_branch_stored_at: { _gte: $date }
      }
    ) {
      aggregate {
        count
      }
      nodes {
        id
        loan_number
        partner_branch_stored_at
      }
    }
  }
`;

const GET_UNRESOLVED_CALLBACK_REQUESTS = gql`
  subscription getUnresolvedCallbackRequests {
    callback_requests_aggregate(where: { is_resolved: { _eq: false } }) {
      aggregate {
        count
      }
      nodes {
        id
      }
    }
  }
`;

const CustomMenuItem = (props) => {
  const { id, text, type, loan_status, ...other } = props;
  return (
    <Link
      to={{
        pathname: `/${type}/view/${id}`,
        state: loan_status
          ? {
              loan_status: loan_status,
            }
          : null,
      }}
    >
      <Menu.Item {...other} key={id}>
        <div className="flex space-x-5 items-center ">
          {type === "loans" ? (
            <div className="  uppercase bg-oro-gradient text-small-bold  h-16 w-16 text-center py-6 rounded-full">
              {type}
            </div>
          ) : (
            <div className="uppercase bg-new-green text-small-bold  text-white  h-16 w-16 text-center py-6 rounded-full">
              {type}
            </div>
          )}
          <div className="h-16 border-b border-gray-200 flex items-center">
          <p className="text-body-bold">{text}</p>
          </div>
        </div>
      </Menu.Item>
    </Link>
  );
};

const Nav = (props) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const { isOnline } = useNetworkStatus();
  const NotificationDropdown = () => {
    return (
      <Menu id="notifications-menu">
        {storedLoans?.loans_aggregate?.nodes?.map((node) => (
          <CustomMenuItem
            id={node.id}
            text={`Gold Stored - ${node.loan_number} `}
            type="loans"
          />
        ))}
        {pendingApprovalLoans?.loans_aggregate?.nodes?.map((node) => (
          <CustomMenuItem
            id={node.id}
            text={`Pending Approval - ${node?.loan_number}`}
            type="loans"
            loan_status="PENDING_OROCORP_APPROVAL"
          />
        ))}
        {renewLoanFundTransfers?.loans_aggregate?.nodes?.map((node) => (
          <CustomMenuItem
            id={node.id}
            text={`Loan ID: ${node?.loan_number} Pending Renewal`}
            type="loans"
            loan_status="RENEWAL_PENDING_FUND_TRANSFER"
          />
        ))}
        {requestedVisits?.visits_aggregate?.nodes?.map((node) => (
          <CustomMenuItem
            id={node.id}
            text={`Visit Requested - ${node?.visit_display_id}`}
            type="visits"
          />
        ))}
      </Menu>
    );
  };

  const { data: requestedVisits } = useSubscription(GET_REQUESTED_VISITS);

  const { data: pendingApprovalLoans } = useSubscription(
    GET_LOANS_PENDING_OROCORP_APPROVAL
  );

  const { data: storedLoans } = useSubscription(GET_STORED_LOANS, {
    variables: { date: moment().startOf("day").subtract(1, "days") },
  });

  const { data: renewLoanFundTransfers } = useSubscription(
    GET_LOANS_RENEWAL_PENDING_FUND_TRANSFER
  );

  const { data: unresolvedCallbacks } = useSubscription(
    GET_UNRESOLVED_CALLBACK_REQUESTS
  );

  const [prevRequestedVisitsCount, setPrevRequestedVisitsCount] = useState(0);
  const [
    prevPendingApprovalLoansCount,
    setPrevPendingApprovalLoansCount,
  ] = useState(0);
  const [prevStoredLoansCount, setPrevStoredLoansCount] = useState(0);
  const [prevRenewalLoansCount, setPrevRenewalLoansCount] = useState(0);

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      window.Notification.requestPermission();
    }
  });

  useEffect(() => {
    if (storedLoans) {
      if (
        prevStoredLoansCount !==
          storedLoans?.loans_aggregate?.aggregate?.count &&
        storedLoans?.loans_aggregate?.aggregate?.count !== 0
      ) {
        new window.Notification("New loans stored!", {
          dir: "ltr",
          icon: NotificationIcon,
        });
        setPrevStoredLoansCount(
          storedLoans?.loans_aggregate?.aggregate?.count || 0
        );
      }
      if (storedLoans.loans_aggregate.aggregate.count === 0) {
        setPrevStoredLoansCount(0);
      }
    }

    if (renewLoanFundTransfers) {
      if (
        prevRenewalLoansCount !==
          renewLoanFundTransfers?.loans_aggregate?.aggregate?.count &&
        renewLoanFundTransfers?.loans_aggregate?.aggregate?.count !== 0
      ) {
        new window.Notification("New loans pending renewal!", {
          dir: "ltr",
          icon: NotificationIcon,
        });
        setPrevRenewalLoansCount(
          renewLoanFundTransfers?.loans_aggregate?.aggregate?.count || 0
        );
      }
      if (renewLoanFundTransfers.loans_aggregate.aggregate.count === 0) {
        setPrevRenewalLoansCount(0);
      }
    }

    if (pendingApprovalLoans) {
      if (
        prevPendingApprovalLoansCount !==
          pendingApprovalLoans?.loans_aggregate?.aggregate?.count &&
        pendingApprovalLoans?.loans_aggregate?.aggregate?.count !== 0
      ) {
        new window.Notification("New loans pending approval!", {
          dir: "ltr",
          icon: NotificationIcon,
        });
        setPrevPendingApprovalLoansCount(
          pendingApprovalLoans?.loans_aggregate?.aggregate?.count || 0
        );
      }
      if (pendingApprovalLoans.loans_aggregate.aggregate.count === 0) {
        setPrevPendingApprovalLoansCount(0);
      }
    }

    if (requestedVisits) {
      if (
        prevRequestedVisitsCount !==
          requestedVisits?.visits_aggregate?.aggregate?.count &&
        requestedVisits?.visits_aggregate?.aggregate?.count !== 0
      ) {
        new window.Notification("New visits requested!", {
          dir: "ltr",
          icon: NotificationIcon,
        });
        setPrevRequestedVisitsCount(
          requestedVisits?.visits_aggregate?.aggregate?.count || 0
        );
      }
      if (requestedVisits?.visits_aggregate?.aggregate?.count === 0) {
        setPrevRequestedVisitsCount(0);
      }
    }
  }, [
    requestedVisits,
    pendingApprovalLoans,
    renewLoanFundTransfers,
    storedLoans,
    unresolvedCallbacks,
    prevPendingApprovalLoansCount,
    prevRequestedVisitsCount,
    prevStoredLoansCount,
    prevRenewalLoansCount,
  ]);

  return (
    <>
      <Modal
        visible={visible}
        footer={null}
        width={486}
        centered
        closable={true}
        closeIcon={<CloseOutlined />}
        keyboard={true}
        onCancel={()=>setVisible(false)}
      >
        <div className="relative">
          <div className="absolute right-2">
            <CloseOutlined
              onClick={() => setVisible(false)}
              className="text-2xl"
            ></CloseOutlined>
          </div>
          <div className="flex flex-col space-y-10  align-middle text-center">
            <span className="mx-auto">
              <img src={Warning} alt=""></img>
            </span>
            <div className="flex space-y-1 flex-col">
              <p className="modal-popup-text">Warning!</p>
              <p className="modal-popup-text  w-3/4 mx-auto">
                Are you sure you want to Logout?
              </p>
            </div>
            <div className="flex space-x-5 ">
              <button
                className="bg-inactive px-20 rounded-full  py-2  text-body-bold"
                onClick={() => setVisible(false)}
              >
                Cancel
              </button>
              <button
                className="bg-oro-gradient rounded-full px-20  text-body-bold py-2 shadow-lg "
                onClick={async () => {
                  setVisible(false);

                  logout().then((res) => {
                    if (res.code === "200") {
                      deleteAllCookies();
                      clearAllLocalStorage();
                      history.replace("/");
                    } else {
                      notification.error({
                        message: "Something went wrong",
                        description: res.message,
                      });
                    }
                  });
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div
        className="bg-white flex w-full justify-between border-b h-20 border-gray-200"
      >
        <div className="p-3 cursor-pointer" onClick={() => window.location.replace('/visits')}>
          <img src={Logo} alt="orocorp-logo" className="h-12 ml-5 w-auto" />
        </div>
        <div className="flex justify-between items-center space-x-4 mr-5">
          <Dropdown
            overlay={NotificationDropdown}
            trigger={["click"]}
            placement="bottomLeft"
            className="relative ml-10"
          >
            <div className="flex  space-x-1">
              <span
                className="ant-dropdown-link text-black text-body-bold pr-0 cursor-pointer"
                onClick={(e) => e.preventDefault()}
              >
                Notifications
              </span>
              <span>
                <img src={NotificationIcon} alt="" />
              </span>
            </div>
          </Dropdown>
            <div className='flex items-center space-x-3 ml-5'>
                <div className='w-38 h-38 rounded-full bg-border-grey flex items-center justify-center relative'>
                  <UsersIconSidebar />
                  {isOnline ? (
                    <div className='absolute bg-online-green h-2 w-2 rounded-full border bottom-0 right-1' />
                  ) : null}
                </div>
                
                  <p className='text-base font-bold text-[#454545] transition-opacity duration-500'>
                  {getValueFromCookie("user")?.email_id}
                  </p>
			      </div>
        </div>
      </div>
    </>
  );
};

Nav.protoTypes = {
  title: PropTypes.string,
};

export default Nav;
